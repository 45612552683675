<template>
  <div>
    <general-table
      :api-url="apiUrl"
      :add-component-name="addComponentName"
      :edit-component="editComponent"
      :delete-content="false"
      :block-content="false"
      :edit-content="false"
      :view-content="true"
      :add-type="addType"
      :columns="columns"
      :type="type"
      :selectable="false"
    >
      <template #cell(coruses)="data">
        <div style="margin: 6px;">
          <span
            title="View"
            class="cursor-pointer hoverbtn"
            @click="
              $router.push({
                name: 'courses',
                params: { C_id: data.item.id }
              })
            "
          >
            Add , Edit and show Courses
          </span>
        </div>

      </template></general-table>
  </div>
</template>

<script>
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },
  data() {
    return {
      APIURL: 'engineer',
      // addType: 'Add engineer',
      // addComponentName: 'add-engineer',
      // editComponent: 'edit-engineer',
      viewContent: true,
      type: 'page',
      partners: [],
      filter: {},
      types: '',
      columns: [
        { key: 'id', sortable: true },
        { key: 'name', label: 'Name Ar' },
        { key: 'email', label: 'Email' },
        { key: 'department_name', label: 'Department' },
        { key: 'job_description', label: 'Job Description' },
        { key: 'joined_at', label: 'Joined At' },
        { key: 'office.name', label: 'Office' },
        // { key: 'coruses', label: 'Coruses' },
      ],
    }
  },

  computed: {

    apiUrl() {
      let base = 'engineer?'

      if (!Object.values(this.filter).length) {
        return base.slice(0, -1)
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(this.filter)) {
        if (key && value) {
          base += `${key}=${value}&`
        }
      }
      // console.log(base.slice(0, -1))
      return base.slice(0, -1)
    },
  },
  mounted() {
  },

  methods: {

    filterTypes(type) {
      const filteredType = this.status.filter(item => item.text !== type)
      this.types = filteredType
    },

  },
}
</script>

<style>
.hoverbtn{
  border: 1px solid;
  padding: 5px !important ;
  border-radius: 5px;
}
.hoverbtn:hover{
  border: 1px solid white;
  background-color: black;
  color: white;
}
</style>
